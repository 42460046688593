<template>
  <form @input="$emit('input', formattedLetterWords)">
    <div v-for="(item, i) in letterWords" :key="i">
      <input type="text" v-model="item.word" placeholder="Correct Word" />

      <div class="ml-40">
        <input type="text" v-model="item.emptyNumbers" placeholder="Ex: 1, 2, 3" />
        <input type="text" v-model="item.lettersToShow" placeholder="Ex: a, b, c" />
      </div>
    </div>

    <button type="button" @click="addItem">
      + Add Item
    </button>

    <pre
      style="
        position: fixed;
        z-index: 100000;
        right: 10px;
        bottom: 10px;
        background: rgba(10, 0, 40, .9);
        color: #fff;
        font-size: 12px;
        padding: 8px;
        border-radius: 8px;
        line-height: 1;
        text-align: left;
        max-height: 90vh;
        overflow: auto;
        pointer-events: none;
      "
    >
      {{ this.formattedLetterWords }}
    </pre>
  </form>
</template>

<script>
// "letterWords": [
//     {
//       "word": "aeroplane",
//       "emptyNumbers": [2,4,6],
//       "lettersToShow": ["e","o","l"]
//     }
//   ]
export default {
  name: 'Type5b',
  data() {
    return {
      letterWords: [
        {
          word: '',
          emptyNumbers: '',
          lettersToShow: '',
        },
      ],
    };
  },
  computed: {
    formattedLetterWords() {
      let result = JSON.parse(JSON.stringify(this.letterWords)).map(lw => {
        lw.emptyNumbers = lw.emptyNumbers.replace(/[\,\/]/gi, ',').split(',').map(v => +v)
        lw.lettersToShow = lw.lettersToShow.replace(/\s/g, '').replace(/[\,\/]/gi, ',').split(',')
        return lw
      })
      return result;
    },
  },
  methods: {
    addItem() {
      this.letterWords.push({
        word: '',
        emptyNumbers: '',
        lettersToShow: '',
      });
    },
  },
};
</script>
